import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class NftService {
  public readonly apiUrl = environment.API_BASE_URL;
  public headers: HttpHeaders;
  constructor(public http: HttpClient, public sanitizer: DomSanitizer) {
    // set token if saved in local storage
  }

  // Check Token
  createImage(count: any, txn: any, currentPrice: any, transaction:any, type: any) {
    this.headers = new HttpHeaders();
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set(
      'Access-Control-Allow-Headers',
      'Origin, Authorization, Content-Type, Accept'
    );
    return this.http
      .post(
        `${this.apiUrl}create-image?count=${count}&txn=${txn}&price=${currentPrice}&type=${type}`,
        { data: transaction },
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
}
