import { ConnectService, NftService, UtilityService } from './../../_services';
import { Component, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  constructor(
    private connectService: ConnectService,
    private nftService: NftService,
    private utility: UtilityService,
    private formBuilder: FormBuilder
  ) {}

  account = '';
  selectedToken: any = 1;
  totalMintedToken = 0
  currentPrice:any = 0.07;
  isSaleStarted = false

  async ngOnInit() {

      this.account =
        this.connectService.account !== '' &&
        this.connectService.account !== null
          ? this.connectService.account
          : '';


  }

  async ngAfterViewInit() {
    setTimeout(async () => {
      await this.clickButton()
      this.currentPrice = this.connectService.currentPrice;
      this.isSaleStarted = this.connectService.saleStarted;

    }, 1000);
  }

  async clickButton() {
    this.utility.startLoader('Connecting..');
    await this.connectService.connectWallet();
    this.account = this.connectService.account;
    await this.getTotalSupply();
    this.utility.stopLoader();
  }

  async getTotalSupply() {
    this.totalMintedToken = await this.connectService.getTotalSupply();
  }

  changeNo(type) {
    if (type === 'plus') {
      if (this.selectedToken < 10) {
        this.selectedToken++;
      } else {
        this.utility.showErrorAlert('Warning', 'You can mint only 10 tokens at a time');
      }
    } else {
       if (this.selectedToken > 1) {
         this.selectedToken--;
       }
    }
  }

  async mintToken() {
    this.utility.startLoader();
    const mintData = await this.connectService.mint(
      this.selectedToken,
      this.selectedToken * this.currentPrice
    );

    console.log(mintData);


    if (!this.connectService.isIOS() && mintData && (mintData.transactionHash || mintData.hash)) {
      let transaction = [];
      if (mintData.events.ReturnEvent.returnValues) {
        transaction.push(mintData.events.ReturnEvent);
      } else {
        transaction = mintData.events.ReturnEvent;
      }
      this.nftService
        .createImage(
          this.selectedToken,
          mintData.transactionHash,
          this.currentPrice,
          transaction,
          'web'
        )
        .subscribe(
          async (res) => {
            let data: any = res;
            this.utility.stopLoader();

            var msg =
              'You have been alloted the NFT. Please go to opensea and reveal your NFT!';

            Swal.fire({
              title: 'Congratulations!',
              allowOutsideClick: false,
              html: msg,
              icon: 'success',
              confirmButtonText: 'Ok',
            }).then(function () {
              window.location.reload();
            });
          },
          (error) => {
            this.utility.stopLoader();
            this.utility.showErrorAlert('Error', error);
          }
        );
    } else if (
      this.connectService.isIOS() &&
      mintData && mintData.hash
    ) {

       setTimeout(() => {

         let transaction = [];

         this.nftService
           .createImage(
             this.selectedToken,
             mintData.hash,
             this.currentPrice,
             transaction,
             'mobile'
           )
           .subscribe(
             async (res) => {
               let data: any = res;
               this.utility.stopLoader();

               var msg =
                 'You have been alloted the NFT. Please go to opensea and reveal your NFT!';

               Swal.fire({
                 title: 'Congratulations!',
                 allowOutsideClick: false,
                 html: msg,
                 icon: 'success',
                 confirmButtonText: 'Ok',
               }).then(function () {
                 window.location.reload();
               });
             },
             (error) => {
               this.utility.stopLoader();
               this.utility.showErrorAlert('Error', error);
             }
           );
       }, 20000);

    } else if (mintData !== null) {
      this.utility.stopLoader();
      this.utility.showErrorAlert(
        'Error',
        'MetaMask Tx Signature: User denied transaction signature.'
      );
    } else {
      this.utility.stopLoader();
    }
  }

  disconnectMetamask() {
    this.connectService.disconnect();
    this.account = '';
  }
}
